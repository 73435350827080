<template>
  <div class="accounting-create-invoice pt-4 pb-8 w-full" v-if="order">
    <p class="title">Akontorechnung erstellen</p>
    <vs-col vs-offset="6" vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
      <vs-switch color="success" v-model="displayShortDescriptions" name="displayShortDescriptions" class="mr-2"/>    <label for="displayShortDescriptions"><b>Ausführliche Darstellung</b></label>
    </vs-col>
    <h2>Rechnungen ohne Positionszuordnung</h2>
    <p>Alle Rechnungspositionen die diesem Gesamt-Angebot zugewiesen sind.</p>
    <vue-bootstrap4-table
            v-if="invoicesWithoutPositionsData"
            :rows="invoicesWithoutPositionsData"
            :columns="columnsInvoicesWithoutPositionsTable"
            :config="config"
            :classes="{table: 'row-select'}"
            :total-rows="total_rows"
            @on-change-per-page="onChangePerPageInvoiceWithoutPositions"
            class="mt-4 pb-8 mt-8 border-bottom-light">

      <template slot="empty-results">
        Keine Rechnungen gefunden.
      </template>

      <template slot="status" slot-scope="props">
        <span class="vbt-table-td">
         <span v-html="props.row.order.order_status.label"> {{props.row.order.order_status.label}}</span>
       </span>
      </template>

      <template slot="order_date" slot-scope="props">
       <span class="vbt-table-td">
         {{ formatDate(props.row.order.order_date) }}
       </span>
      </template>

      <template slot="amount" slot-scope="props">
        <span class="vbt-table-td">
          {{ props.row.amount}} {{getVolumeUnitNameById(props.row.volume_unit)}}
       </span>
      </template>

      <template slot="product_name" slot-scope="props">
        <span class="vbt-table-td">
          <b>{{ props.row.product_name}}</b><br>
          <small v-html="props.row.short_description" v-if="displayShortDescriptions">{{ props.row.short_description}} </small>
       </span>
      </template>

      <template slot="actions" slot-scope="props">
        <router-link :to="'/invoices/view/'+props.row.order.id">
          <vx-tooltip text="Ansehen" position="left">
            <vs-button color="dark" type="border"
                       icon="visibility" size="small"
            ></vs-button>
          </vx-tooltip>
        </router-link>
      </template>
    </vue-bootstrap4-table>

    <br>
    <br>


    <vs-row class="mt-4">
      <vs-alert color="primary" :active="true" icon="info" class="mb-4" >
       Vergewissern sich, das Sie die bereits berechten Positionen sich angeschaut haben.
      </vs-alert>
    </vs-row>

    <vs-row vs-w="6" class="block">
        <div class="flex pt-4">
          <div class="w-1/5">Typ:</div>
          <div class="w-1/2 flex">
            <vs-radio v-model="typCheckbox" class="pr-8" vs-name="radios1" vs-value="percent" v-on:change="generatePositionsData">%</vs-radio>
            <vs-radio v-model="typCheckbox" vs-name="radios1" vs-value="sum" v-on:change="generatePositionsData">Betrag</vs-radio>
          </div>
        </div>
        <div class="flex pt-4" v-if="typCheckbox == 'percent'">
          <label class="w-1/4 pt-2">Gesamtsumme</label>
          <mg-select v-model="sumTyp" :options="options1"  name="total"
                     placeholder="Gesamtsumme auswählen"
                     track-by="value"
                     label="text"
                     data-vv-as="Gesamtsumme"
                     v-validate="'required'"
                     @select="onSelectSumType">
          </mg-select>
          <span class="text-danger text-sm" v-if="errors.has('clients')">Dieses Feld ist ein Pflichtfeld</span>
        </div>
        <div class="flex pt-4 pb-4" v-if="sumTyp == 3 || typCheckbox == 'sum'">
          <span class="w-1/5 pt-2"><span v-if="typCheckbox == 'percent'">Andere </span>Summe: </span>
          <vs-input class="w-1/2" v-model="customSum"  v-on:change="generatePositionsData"/>
        </div>
      <div class="flex pt-4 pb-4 border-bottom-light"  v-if="typCheckbox == 'percent'">
        <span class="w-1/5 pt-2">Wie viel Prozent? </span>
        <vs-input class="w-1/2" v-model="percentage" v-on:change="generatePositionsData"/> <span class="pt-2 pl-2">%</span>
      </div>
    </vs-row>

    <vs-row vs-w="6" class="block mt-6">
      <span>Positionsbezeichnung:</span>
      <vs-input class="w-full" v-model="positionsData.article_name"></vs-input>
      <vs-alert color="#e24733" :active="true" icon="error" class="mb-4 mt-2"
                v-show="!positionsData.article_name">
        Titel darf nicht leer sein
      </vs-alert>

      <label class="vs-input--label mt-4 block">Beschreibung</label>
      <vue-editor name="short_description" v-model="positionsData.short_description" :editorToolbar="toolbar" data-vv-as="Beschreibung"  />

      <div class="amount mt-6">Summe: {{formatCurrency(positionsData.unit_price)}}</div>
      <button type="button" @click="saveAccounting" name="button" class="vs-component vs-button vs-button-success vs-button-filled mt-6"> Abrechnung erstellen</button>
    </vs-row>
  </div>
</template>

<script>

import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
import MgSelect from "../../../../components/mg-select/MgSelect";
import ApiService from "../../../../api";
import PriceHelper from "../../../../mixins/helper/price.helper";
import QueryHelper from "../../../../mixins/helper/query.helper";
import * as qs from "qs";
import moment from "moment";
import staticOptions from "../../../../mixins/static/options";
import {mapGetters} from 'vuex';

export default {
  name: "AccountingCreateInvoice",
  components: {
    VueBootstrap4Table,
    MgSelect
  },
  data: function() {
    return {
      order: [],
      positionsData: {
        workspace_id: null,
        client_id: null,
        article_id: 73,
        at_least_date_accounting: null,
        amount: 1,
        tax: 19,
        volume_unit: 1,
        unit_price: 0,
        purchase_price: 0,
        ek_price: 0,
        offer_id: null,
        article_name: null,
        short_description: null,
        contract_id: null,
        contract_items_id: null,
        contract_start: null,
        contract_end: null,
        accounting_start: null,
        accounting_end: null,
        is_hour_unit: 0,
        unit_multiplicator: 1
      },
      typCheckbox: "percent",
      itemtitle: "",
      sumTyp: 2,
      customSum: null,
      percentage: 30,
      options1:[],
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
      /*
      Invoices without order_item relationship to offer.order_item  // only offer_id relationship to orders
      */
      displayShortDescriptions: false,
      invoicesWithoutPositionsData: [],
      columnsInvoicesWithoutPositionsTable: [
        {
          label: "Status",
          name: "order.status",
          slot_name: "status",
          row_text_alignment: 'text-left'
        },
        {
          label: "Rechnungsnummer",
          name: "order.invoice_number",
          sort: true,
          row_text_alignment: 'text-left'
        },
        {
          label: "Rechnungsdatum",
          name: "order.order_date",
          row_text_alignment: 'text-left',
          slot_name: "order_date",
        },
        {
          label: "Positionsbeschreibung",
          name: "product_name",
          slot_name: "product_name",
          row_text_alignment: 'text-left',
          sort: true
        },
        {
          label: "Menge",
          name: "amount",
          slot_name: "amount",
          row_text_alignment: 'text-left',
          sort: true
        },
        {
          label: "Einzelpreis",
          name: "unit_price_netto",
          row_text_alignment: 'text-left',
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Gesamtpreis",
          name: "sum_price_netto",
          slot_name: "sum_price_netto",
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Actions",
          slot_name: "actions",
          sort: false,
          showCol: true,
          width: '80px',
        },
      ],
      config: {
        global_search:  {
          visibility:  false,
        },
        checkbox_rows: false,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 20,
      },
      total_rows: 0,
      sumTotal: null,
      sumTotalService: null,

    }
  },
  computed: {
    ...mapGetters([
      'workspace'
    ]),
  },
  created() {
    this.positionsData.workspace_id = this.workspace.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$vs.loading()
      this.fetchOrderData().then((response) => {

        this.getInvoiceWithoutPositionsData();
        this.calculateSumTotalService();
        this.fillSumSelectOptions();
        this.generatePositionsData();

        this.$vs.loading.close()
      });

    },
    async fetchOrderData() {
      const id = this.$route.params.id;
      return ApiService.get(`orders/${id}`).then(response => {
        this.order = response.data.result;
      }).catch(response => {
      })
    },
    getInvoiceWithoutPositionsData() {
      let id = this.order.client_id;
      let offerId = this.order.offer_id;
      let offerItemId = this.order.offer_item_id;

      let queryParams = {
        filters: [],
        limit: 10000,
        offset: 0,
      };

      let filterParams = QueryHelper.parseRequestParams(queryParams);

      filterParams.filter.push(
              {
                field: 'accountings.offer_id',
                value: this.$route.params.id,
                expression: 'exact',
              },
              {
                field: 'accountings.offer_item_id',
                value: 'null',
                expression: 'exact',
              },
      );
      filterParams.includes =  "order.orderStatus,article.type";

      ApiService.get('order_items', {
        params: filterParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.invoicesWithoutPositionsData = response.data.result;
      })
    },
    saveAccounting() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$vs.loading();

          this.positionsData.offer_id = this.order.id;
          this.positionsData.client_id = this.order.client_id;
          this.positionsData.unit_price = PriceHelper.parseCommaFloat(this.positionsData.unit_price);
          this.positionsData.ek_price = PriceHelper.parseCommaFloat(this.positionsData.ek_price);


          ApiService.post('accountings', this.positionsData).then((response) => {

            let id = response.data.data.id;
            this.$router.push({name: 'accounting-edit', params: { id: id }});
           // this.$router.push({name: 'accountings'});
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Erfolgreich',
              text: 'Die Abrechnung wurde erfolgreich gespeichert.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
          }).catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Fehler',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });
        }
      });

    },
    onSaveOrder() {
      const id = this.$route.params.id;
      const postData = {
        title: this.order.title,
        short_description: this.order.short_description,
      };

      this.$vs.loading();

      ApiService.put(`orders/${id}`, postData).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    onChangePerPageInvoiceWithoutPositions(value) {
      this.configInvoiceWithoutPositionsTable.per_page = value;
    },
    formatDate() {
      return moment().format('DD.MM.YYYY')
    },
    getVolumeUnitNameById(id) {
      const units = staticOptions.units.find(item => item.value === parseInt(id));
      if (units) {
        return units.labelShort
      } else {
        return "unbekannt"
      }
    },
    calculateSumTotalService() {
      var sum = 0;

      if(this.order != undefined && this.order.items && this.order.items.length > 0) {
        for (let i = 0; i < this.order.items.length; i++) {
          if (this.order.items[i].type == 'article' && this.order.items[i].optional_item == 0 && (this.order.items[i].is_hour_unit == 1)) {
            sum = sum + this.order.items[i].sum_price_netto
          }
        }
      }
      this.sumTotalService = sum - (sum * this.order.discount/100);
    },
    fillSumSelectOptions(){
      this.options1 = [
        {text:'Gesamtsumme: ' + PriceHelper.formatCurrency(this.order.sum_netto),value:1},
        {text:'Summe (Personalaufwände): ' + PriceHelper.formatCurrency(this.sumTotalService),value:2},
        {text:'Andere Summe:',value:3},
      ];
    },
    formatCurrency(number) {
      return PriceHelper.formatCurrency(number);
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onSelectSumType(value) {
      this.sumTyp = value.value;
      this.generatePositionsData();
    },
    generatePositionsData() {

      this.positionsData.article_name = "Abschlagszahlung zu Angebot #"+this.order.invoice_number + " - " + this.order.title + " vom " + this.formatDate(this.order.order_date);

      if(this.typCheckbox == "percent") {
        if(this.sumTyp == 1) {
          console.log("test")
          this.positionsData.short_description = this.percentage+"% von Gesamtsumme: " + PriceHelper.formatCurrency(this.order.sum_netto);
          this.positionsData.unit_price = this.order.sum_netto * (this.percentage/100);
        }
        if(this.sumTyp == 2) {
          this.positionsData.short_description = this.percentage+"% der Gesamtsumme (Personalaufwände): " + PriceHelper.formatCurrency(this.sumTotalService);
          this.positionsData.unit_price = this.sumTotalService * (this.percentage/100);
        }
        if(this.sumTyp == 3) {
          this.positionsData.short_description = this.percentage+"% von Summe: " + PriceHelper.formatCurrency(this.customSum);
          this.positionsData.unit_price = this.customSum * (this.percentage/100);
        }
      }
      if(this.typCheckbox == "sum") {
        this.positionsData.short_description = "";
        this.positionsData.unit_price = this.customSum;
      }

    }

  },

}
</script>

<style lang="scss">
.accounting-create-invoice {
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .summary {
    background-color: #f8f8f8;
    padding: 15px;
    border: 1px solid #dccbcb;
    border-radius: 3px;
  }
  .border-bottom-light {
    border-bottom: 1px solid #dccbcb;
  }
  .vs-button {
    margin: 0 auto;
  }
  .orange {
    color: #ec7b24;
  }
  .amount {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
